/* :root {
    --bs-navbar-toggler-icon-bg: url('path/to/your/icon.png');
  } */
  .bg-others{background: rgb(203, 228, 244)}
  .cms{display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;}
.slide-res{    max-width: 98%;}
.img-ress .slick-slider{width: 98%;}
.slide-res .slick-list{
    padding-left: 33px;}
p{font-family: 'poppins'}
.nav-li{color: black;}
.login{background: #2AA8FF;
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.02em;
text-align: left;
color: #fff;
border-radius: 8px;}
.blog-img{width: 100%;}
.fam-img{padding: 60px 60px 10px 60px;}
form{font-family: 'Poppins';}
.time-slt{background-color: #0070fc1f;
    padding: 15px;
    border-radius: 14px;}
.bg-clr{background: #ffffff70;
    /* padding: 24px; */
    border-radius: 13px;}
.bg-img{background-image: url('..//src/components/img/image-1.png');
    border: none !important;
    height: auto;
    height: 175px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    margin: 10px 10px;
    align-items: center;}
    .bg-img2{background-image: url('..//src/components/img/image-2.png');
        border: none !important;
        margin: 10px 10px;
        height: auto;
        height: 175px;
    width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;}
        .bg-clr2{background-color:#2aa8ff29;    margin-top: 50px;}
        .cat-head{text-align: center;}
        .cat-head h1{    padding: 40px 10px;}
        .cat-size{
            text-align: center;
            background: #fff;
            border-radius: 12px;
  }
    .para1{margin-top: 10px;}
    .legend {
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 10px;
        font-size: 18px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
      }
      .legend1 {
        color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        font-size: 22px;
        font-weight: 600;
        font-family: 'poppins';
        position: relative;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
      }
      .sub-para{font-size: 15px;
        font-weight: 500;
        color: #2AA7FF;}
      .pat{
        font-size: 16px;
    font-weight: 900;
    font-family: 'Poppins';
    color: #2AA7FF;
      }.b-para{    font-weight: 600;}
      .sub-btn{background-color: #2AA8FF;
        color: #fff;}
      .cntr{margin-top: 40px;}
      .pat2{color: #000;}
      .pat-head{font-size: 48px;
        font-family: 'Poppins';
        font-weight: 600;
    color: #1B3C74;}
    .pat-head1{font-size: 48px;
        font-family: 'Poppins';
        font-weight: 600;
    color: #2aa7ff;}
        .pat-head span{
            color: #2AA7FF;
        }
        .para2{color: #77829D;
        font-size: 17;
    }
    ul li{    list-style: none;
        color: #1B3C74;
    }
    .bp1, .bp2{    font-family: 'Poppins';}
    .bcls h5{    font-family: 'Poppins';
        font-weight: 700;}
        /* .mimg{    width: 100%;
        } */
         .cntr{    text-align: center;
            margin-top: 29%;}
            .btn02{
                width: 40% !important;
            }
            .btn002{
                width: 100% !important;
            }.btns1{margin-top: 10px;}
            .hr-clr{border-top: var(--bs-border-width) solid #fff !important;}
        .blg{    border: 1px solid #ccc;    border-radius: 10px;}
        .gimg{width: 100% }
        .btn-sub{background-color: #2AA8FF;color: #fff;}
        .bpara{
            padding: 10px;
        }.img-wid{width:75%;}
        .b-padd{    padding: 81px 0px 0px 0px;}
        .accordion-button{font-family: 'Poppins';
            font-weight: 700;
            font-size: 16px;}
            .bg-cl40{background: rgb(203, 228, 244)}
            .bg-clr3{
                /* background-color: #E7F0FF; */
                border: none !important;
                margin: 10px 10px;
                
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                    background-size: contain;}
                    .footer{
                        color: #fff;}
                        .foo1{}
                            .f_sty{background-color:'#1B3C74';
                            margin-top: -15px;}
                            .btn-outline-secondary{background: #fff;}
                            .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
                                border-color: #ccc;}
                                .form_h{background: #ffffff70;
                                    padding: 24px;
                                    border-radius: 13px;
                                    margin-top:40px;
 }.cpr{padding: 45px 0px;}.r-cpy{border-top: 1px solid #fff;}
 .r-foo{padding-bottom: 44px;}
                                .foo-list li{color:#fff;    line-height: 28px;}
                                .popup{    padding: 16px;
                                    background: #ffffff78;
                                    border-radius: 13px;
                                    margin-top: 12px;
                                }
                                .pat3{color: #02A401;
                                    font-weight: 700;
                                    font-size: 21px;}
                                    .div-des{    padding: 12px 0px 0px 0px;
                                        text-align: left;}
                                    .d-info{ border-radius: 12px;
                                        background: #fff;
                                        margin-top: 25px;
                                        border: 1px solid #ccc;}
                                        .btns{width: 100%;}
                                        .dr-img{padding: 20px;}
                                        .cal{color:#fff;
                                             text-decoration: none}
                                             .dr-img .mimg{border: 2px solid #0070fc;
                                                border-radius: 100px;    width: 80%;}
                                                .dr-img .mimg02{border: 2px solid #0070fc;
                                                    border-radius: 100px;    width: 80%;}
                                                .mimg1{margin-top: -103px;margin-left: -32px;
                                                    width: 10%;}
                                                .img-bdr{border-right: 1px solid #ccc;
                                                }
                                                .full-width-height {
                                                    width: 100%;
                                                    height: 100vh;
                                                    background-color: lightblue;
                                                  }
                                                  .main-p{color: #fff;
                                                    text-align: center;
                                                    font-family: 'poppins';
                                                    font-size: 15px;
                                                    padding-top: 11px;
                                                  }
                                                  .main-p1{color: #000;
                                                    text-align: left;
                                                    font-family: 'poppins';
                                                    font-size: 15px;
                                                    padding-top: 11px;
                                                  }
                                                  .btn-bg{    background: #2AA8FF;
                                                    border-radius: 5px;}
                                                    .btn-bg a{color: #fff;}
                                                    .btn-bg01{    background: #4ec702;
                                                        border-radius: 5px;}
                                                        .btn-bg01 a{color: #fff;}
                                                    .bg-light{
                                                        background-color: #fff !important;
                                                    }
                                                    .sli-bg{background-color: #E8F1FF !important;}
                                                    .main-h{font-family: 'poppins';
                                                        font-weight: 800;}
                                                    .sub-h{font-family: 'poppins';font-size: 24px}
                                                    .main-p{    font-family: 'poppins';
                                                        font-size: 15px;
                                                        color: #fff;
                                                        text-align: left;}
                                                        .tb-fie{width: 100%;}
                                                        .filed-wid{width: 40%;}
                                                        .btn-wid{width: 40%;}
                                                        .main-btn{
                                                            color: #fff;
                                                            background: #2AA8FF;
                                                            width: 24%;
                                                            border-radius: 6px;}
                                                            .main-btn .btn{color:#fff}
                                                            .sli-bg1 .slick-dots {
                                                                background: #e5f1ff;
                                                                padding: 10px 14px 22px;}
                                                                .nav-link {
                                                                    font-family: 'Poppins';
                                                                    font-weight: 400;}
                                                                    .bgimg02{
                                                                        background-image: url('./components/img/image-2.png');
                                                                        background-repeat: no-repeat;
                                                                    }
                                                                    .bgimg03{
                                                                        background-image: url('./components/img/image-01.png');
                                                                        background-repeat: no-repeat;
                                                                    }
                                                                    .bgimg04{
                                                                        background-image: url('./components/img/image-01.png');
                                                                        background-repeat: no-repeat;
                                                                    }
                                                                    .hidden {
                                                                        display: none;
                                                                    }
                                                                    
                                                                    #toggleDiv {
                                                                        background-color: lightblue;
                                                                        padding: 20px;
                                                                        border: 1px solid #ccc;
                                                                        border-radius: 13px;
                                                                        margin-bottom: 16px;
                                                                    }



                                                                    /* For Mobile */
                                                                    @media (max-width: 768px) {
                                                                        .bg-res{
                                                                          margin-top: 0px !important; /* Adjust height for tablets */
                                                                        }
                                                                        .for-mob-btn{padding: 0px 10px 20px 10px;}
                                                                        .img-bdr{border: none !important;}
                                                                        .lbl-size{    font-size: 12px;}
                                                                        .btn02{width: 100% !important;}
                                                                        .main-p{font-size: 12px;
                                                                            line-height: 1.5;}
                                                                            .btn-bg01{margin-top: 10px;
                                                                                color: #fff;}
                                                                                .mt-4.nav-item a{
                                                                                    color: #fff;
                                                                                }
                                                                                .bg-img{    width: 94% !important;}
                                                                                .pat02 {
                                                                                    font-size: 35px !important;}
                                                                                    .cat-size {
                                                                                        margin-top: 15px;}
                                                                                        .blg{margin-top: 14px;}
                                                                                        .pat-head, .pat-head1 {
                                                                                            font-size: 38px;}
                                                                      }

                                                                    /* Popup overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup inner content */
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    text-align: center;
    max-width: 600px;
  }
  
  /* Close button */
  .popup-close {
    cursor: pointer;
    border: none;
    background: none;
  }
  i.bi.bi-list {
    font-size: 32px;
    color: #2aa7ff;
    float: right;
}
.vdo-bg1{
background: rgb(34 34 34);}
.col-siz {
    padding: 53px;
}
