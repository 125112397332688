/* Signup.css */
.signup-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  .bg-img{
 background-image: url('./components/img/sign-in.webp');
 width: 50%;
 height: auto;
 padding: 59px;
 }
 .rw{box-shadow: -1px 3px 12px 9px #8d8a8a5c;
    border-radius: 14px;
    background-color: #fff;}
  .cls-align{width:100%;
}
.col-pd{padding: 49px 22px 46px 46px;}
  .pat02{    font-size: 55px;
    font-weight: 700;}
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  