p{font-family: 'poppins'}
.rec{    font-size: 20px;
    color: #fff;}
.mt-sty{
    background: #0c0c0cc7;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
}
.col-lft{
   
}
.dr-name{    color: #fff;
    padding: 10px 0px 0px 0px;
    font-size: 20px;
    font-family: 'Poppins'}
    .vdo-bg{background-image: url('./components/img/vdo.png');
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 400px;
    border-radius: 33px;
}
.bld1{background: #e4ff00;
    padding: 2px;
    font-size: 13px;}
    .bld2{background: #62ff00;
        padding: 2px;
        font-size: 13px;}
.rg-bg{float: right;
    padding: 19px;}
    .rg-bg1{float: left;
        padding: 138px 0px 25px 15px;
    }
        .rg-bg1  td{padding: 11px 0px 14px 0px;
            text-align: center;}
/* .cht-div{background: #ffffff69;
    padding: 26px 10px;} */
    img.mimg03 {
        border-radius: 38px;
        width: 70%;
    }
    .cht-div{    height: 400px;
        /* width: 195px; */
        border-radius: 10px;
        background: #ffffff69;
        padding: 26px 10px;}
        .cht-div1{      margin-bottom: 17px;
            background: #ffffff69;
            padding: 14px 14px;
            height: 200px;
            overflow-y: auto;
            /* box-shadow: 0px 0px 12px 1px #ffffff91; */
            border-radius: 9px;}
            span.img-icon {
    padding: 7px;}
    img.mimg.img-icres {
        padding: 12px 0px 13px 0px;
    }

        .cht-div .para{      font-size: 15px;  border-bottom: 1px solid #717171;}
       .cht{    font-size: 14px;
            font-weight: 600;}
         .td-wid{
            width: 100%;
         }
         @media (max-width: 768px) {
            .cht-div1{width: 100% !important; 
                margin-bottom: 12px;}
            .rg-bg1{float: none;
                padding: 10px 0px 0px 0px;}
                .col-lft{text-align: center;}
                .vdo-bg{height: auto !important;}
                .cntr .mimg{width: 87%;
                    margin: 10px;}
                    .td-wid, .para002{font-size: 12px;}
                    .xtraheight{height: 50px !important;}

        }